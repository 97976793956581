import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ObjectDynamic } from './ObjectDynamic';
import { EventCal } from './eventCal';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  loginDisplay = false;
  events: EventCal[];
  calendars: any;
  today30: Date;
  today2week: Date;
  today: Date;


  constructor(private authService: MsalService, private msalBroadcastService: MsalBroadcastService, private http: HttpClient) { }

  ngOnInit(): void {

    this.today = new Date();
    this.today30 = new Date(this.today);
    this.today2week = new Date(this.today);
    this.today2week.setDate(this.today2week.getDate() + 15);
    this.today30.setDate(this.today30.getDate() + 30);
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

    if (!this.loginDisplay){
        this.getCalendarEvents();
      }

  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;

  }
  getCalendarEvents() {
    const requestObj = {
      scopes: ['https://graph.microsoft.com/.default']
    };


    this.authService.acquireTokenSilent(requestObj).toPromise().then((tokenResponse: AuthenticationResult) => {
      console.log(tokenResponse.accessToken);
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'OData-MaxVersion': '4.0',
          'OData-Version': '4.0',
          Authorization: 'Bearer ' + tokenResponse.accessToken
        })
      };

      this.http.get<ObjectDynamic[]>('https://graph.microsoft.com/v1.0/users/tech@megahertz.ch/calendars/AAMkAGRkYmFkODVhLTc4Y2ItNDlkZC04N2E3LTljMGFjMWJlODgzMwAuAAAAAAA0Dz0TShLyTaXdVtDwgYhmAQDHMZvdgmKwRa4rr09NJ0pxAAMaOlnVAAA=/events?startDateTime=' + this.today + '&top=1000', httpOptions).subscribe(
      value => {
         console.log(value);
         this.events = JSON.parse(JSON.stringify(value)).value;

         // this.dataSource.data = this.orders;
         // this.isLoadingResults = false;

    });
  });
  }

  get sortData() {
    if (this.events != null){
    return this.events.sort((a, b) => {
      return (new Date(a.start.dateTime) as any) - (new Date(b.start.dateTime) as any);
    });
  }
}

getClassColor(eventCal: EventCal){

  console.log('Today 30: ' + this.today30);
  let eventDateString = eventCal.start.dateTime;
  let eventDate = new Date(eventDateString);
  console.log('Event Date: ' + eventDate);
  if (eventCal.subject.includes('automatique')){
    return 'blue';
  } else if (eventDate > this.today30){
    return 'green';
  }else if (eventDate <= this.today30 && eventDate > this.today2week){
    return 'orange';
  }else{
    return 'red';
  }
}
}
